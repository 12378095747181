import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const SecondPage = () => (
  <Layout>
    <SEO title="Page two" />
    <StaticQuery
          query={graphql`
            query {
              placeholderImage: file(relativePath: { eq: "IMG_3557.jpg" }) {
                childImageSharp {
                  fluid(maxWidth: 1140) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          `}
          render={data => (
            <Img
              fluid={data.placeholderImage.childImageSharp.fluid}
              style={{ width: "100%", marginTop: -38 }}
            />
          )}
        />
    <h2>Igreja</h2>
    <p>Igreja Paroquial de Nossa Senhora dos Remédios: Praça da República, 2775-624 Carcavelos</p>
    <h2>Festa</h2>
    <p>Quinta da Lapa: Largo da Ponte 2, 2710-125 Sintra</p>
    <h2>Utilidades</h2>
    <p>Pernoitar: Oferecemos sofá :) <br/>
Para não perder pontos da Carta: <br/>
UBER - App (disponível para Android e Apple)<br/>
Táxi - 210482170/968015453</p>
    <h2>Contatos</h2>
    <p>Noiva: 912103009 - <a style={{textDecoration: 'underline'}} href="mailto:barbarasilvadias@gmail.com">barbarasilvadias[at]gmail.com</a></p>
    <p>Noivo: 963313311 - <a style={{textDecoration: 'underline'}} href="mailto:jcb.costa@sapo.pt">jcb.costa[at]sapo.pt</a></p>
  </Layout>
)

export default SecondPage
